import React from 'react'
import "../Footer/Footer.css"

export default function Footer() {
  return (
    <div className='footer-container'>
       <div className='footer-parent'>
           <img src={require ('../../../assets/fonts/shape-bg.png')} alt="no internet connection" /> 
       </div>
        </div>
  )
}
