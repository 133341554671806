import React from "react";
import "./app.css"
import PortfolioContainer from "./Profile/PortfolioContainer";


function App() {
  return (
    <div className="App">
    <PortfolioContainer />
    </div>
  );
}

export default App;
